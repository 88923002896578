/*--------------------------------

houpa Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'houpa';
  src: url('../fonts/Icons/houpa.eot');
  src: url('../fonts/Icons/houpa.eot') format('embedded-opentype'), url('../fonts/Icons/houpa.woff2') format('woff2'), url('../fonts/Icons/houpa.woff') format('woff'), url('../fonts/Icons/houpa.ttf') format('truetype'), url('../fonts/Icons/houpa.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'houpa';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.icon-ul > li > .icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icon-slip::before {
  content: "\ea02";
}

.icon-exit::before {
  content: "\ea03";
}

.icon-eye-solid::before {
  content: "\ea04";
}

.icon-eye-slash-solid::before {
  content: "\ea05";
}

.icon-padlock::before {
  content: "\ea06";
}

.icon-success-circle::before {
  content: "\ea07";
}

.icon-arrow-left::before {
  content: "\ea08";
}

.icon-arrow-up::before {
  content: "\ea09";
}

.icon-arrow-right::before {
  content: "\ea0a";
}

.icon-arrow-down::before {
  content: "\ea0b";
}

.icon-houpa::before {
  content: "\ea0c";
}

.icon-success::before {
  content: "\ea0d";
}

.icon-hanger::before {
  content: "\ea0e";
}

.icon-user::before {
  content: "\ea0f";
}

.icon-user-text::before {
  content: "\ea10";
}

.icon-arrows::before {
  content: "\ea11";
}

.icon-location::before {
  content: "\ea12";
}

.icon-box::before {
  content: "\ea13";
}

.icon-credit-card::before {
  content: "\ea14";
}

.icon-warning::before {
  content: "\ea15";
}

.icon-trashcan::before {
  content: "\ea16";
}

.icon-card::before {
  content: "\ea17";
}

.icon-edit::before {
  content: "\ea18";
}

.icon-info-circle::before {
  content: "\ea19";
}

.icon-clothes-hanger::before {
  content: "\ea1a";
}

.icon-favorite::before {
  content: "\ea1b";
}

.icon-stock::before {
  content: "\ea1c";
}

.icon-home::before {
  content: "\ea1d";
}

.icon-truck::before {
  content: "\ea1e";
}

.icon-chat-internal::before {
  content: "\ea1f";
}

.icon-arrow-menu::before {
  content: "\ea20";
}

.icon-cart::before {
  content: "\ea21";
}

.icon-search::before {
  content: "\ea22";
}

.icon-share::before {
  content: "\ea23";
}

.icon-free-shipping::before {
  content: "\ea24";
}

.icon-truck-normal::before {
  content: "\ea25";
}

.icon-truck-express::before {
  content: "\ea26";
}

.icon-user-logout::before {
  content: "\ea27";
}

.icon-filter::before {
  content: "\ea28";
}

.icon-followers::before {
  content: "\ea29";
}

.icon-shopkeeper::before {
  content: "\ea2a";
}

.icon-logo-instagram::before {
  content: "\ea2b";
}

.icon-logo-facebook::before {
  content: "\ea2c";
}

.icon-logo-whatsapp::before {
  content: "\ea2d";
}

.icon-cipher::before {
  content: "\ea2e";
}

.icon-bar-code::before {
  content: "\ea2f";
}

.icon-photo-camera::before {
  content: "\ea30";
}

.icon-back-arrow::before {
  content: "\ea31";
}

.icon-logout::before {
  content: "\ea32";
}

.icon-copy::before {
  content: "\ea33";
}

.icon-eye::before {
  content: "\ea34";
}

.icon-closed-eye::before {
  content: "\ea35";
}

